//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Override primary color variants
// Dark colors
$dark-75:          									#002144; // Custom variable
$dark-65:          									#414656; // Custom variable
$dark-50:          									#024266; // Custom variable
$dark-25:          									#324C65; // Custom variable
// Primary colors

$primary:       									#CFAF4E; // Bootstrap variable
$primary-hover:    									#b19231; // Custom variable
$primary-light:    									#f9f5e8; // Custom variable
$primary-inverse:                                     #000;


// Secondary colors
$secondary:     									#024266; // Bootstrap variable
$secondary-hover:  									#000e1d; // Custom variable
$secondary-light:  									#F0F4F6; // Custom variable

// Success colors
$success:       									#019062; // Bootstrap variable
$success-hover:    								    #017d55; // Custom variable
$success-light:    									#e0fff5; // Custom variable

// Inco colors
$info:         		 								#A6BDCB; // Bootstrap variable
$info-hover:   		 								#8dabbd; // Custom variable
$info-light:   		 								#f0f4f7; // Custom variable

// Warning colors
$warning:       									#017F98; // Bootstrap variable
$warning-hover:    									#015e71; // Custom variable
$warning-light:    									#e8fbff; // Custom variable
$warning-inverse:                                   #000;
// Danger colors
$danger:        									#D1583C; // Bootstrap variable
$danger-hover:     									#ba472c; // Custom variable
$danger-light:     									#faeeeb; // Custom variable

$dark:          									#04182B; // Bootstrap variable
$dark-hover:          								#04182B; // Custom variable
$dark-light:          							    #717788; // Custom variable

$light:         									#F0F4F6; // Bootstrap variable
$light-hover:         								#e4ebef; // Custom variable
$light-light:         								#F0F4F6; // Custom variable

$page-bg:	 									    $light;
$text-muted:                  						$info-hover; // Custom variable
